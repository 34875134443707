import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import { Container, Col, Row, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch, faLinkSlash } from "@fortawesome/free-solid-svg-icons"
import * as styles from "./login.module.css"
import * as globalCss from "../components/styles/global.module.css"
import log from "../components/log"
import Header from "../components/Header"
import { Helmet } from "react-helmet"
const axios = require("axios").default

let verifyCode = async (code: any) => {
  // Prepare
  let endpoint = process.env.APP_API_ENDPOINT

  // Call the API
  try {
    const response = await axios.post(
      endpoint + "/api/auth/verify_magic_link",
      {
        code: code,
      },
      {
        withCredentials: true,
      }
    )

    // Save token to `localStorage`
    if (response?.status === 200) {
      if (typeof window !== "undefined") {
        localStorage.setItem("access_token", response?.data?.data.access_token)
        localStorage.removeItem("last_login_request")
      }
    }

    return {
      code: response?.status,
      message: response?.data?.message,
    }
  } catch (err: any) {
    if (err?.response == undefined) {
      log("error", "Failed reaching API while checking for Magic Link.", {
        exception: err,
      })

      return {
        code: 408,
        message: "Tidak dapat menghubungi server, coba lagi.",
      }
    }

    return {
      code: err?.response?.status,
      message: err?.response?.data?.message,
    }
  }
}

const Login = ({ location }: any) => {
  // Get GET query `code`
  const [code, setCode] = useState(location.search.replace("?code=", ""))
  const [isCodeValid, setIsCodeValid] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [verifyCodeResponse, setVerifyCodeResponse] = useState(<></>)

  React.useEffect(() => {
    if (code == '') {
      log('warning', 'Query `code` return null.', {
        is_read: true,
      })

      setVerifyCodeResponse(<>Tautan Ajaib tidak bisa digunakan, harap coba login kembali.</>)
      setIsCodeValid(false)
      setIsLoading(false)
    } else {
      // Try to verify `code` to the backend
      verifyCode(code)
        .then(
          function (value) {
            if (value?.code === 200) {
              setIsCodeValid(true)
              setVerifyCodeResponse(<>{value?.message}</>)
              navigate("/dashboard")
            } else {
              setVerifyCodeResponse(<>{value?.message}</>)
            }
          },
          function (error) {
            setVerifyCodeResponse(<>Error: {error}</>)
          }
        )
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [code])

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mengarahkan ke Halaman...</title>
      </Helmet>

      <Container className={styles.section}>
        <Row>
          <Col>
            {!isLoading && !isCodeValid ? (
              <>
                <FontAwesomeIcon icon={faLinkSlash} className="text-danger mb-5 fa-5x" /><br />
                <h1>Tautan Rusak!</h1>
                <div className={styles.login}>
                  <div>{verifyCodeResponse}</div>
                </div>
                <Link to="/">
                  <Button variant="primary" className={styles.backToHomeBtn}>
                    Kembali ke Beranda
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <h1>Harap Tunggu Sebentar</h1>
                <div className={styles.login}>
                  <FontAwesomeIcon icon={faCircleNotch} className={globalCss.spin} />

                  <div>Sedang Mempersiapkan Halaman...</div>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
